/* CSS */
.button-26 {
    background: white;
    border: 1px solid white;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FA6304;
    border: 2px solid #FA6304;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    width: 10%;
    min-height: 40px;
    outline: 0;
    
    padding: 12px 14px;
    
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }
  
  .button-26:disabled {
      pointer-events: none;
    }
    
    .button-26:hover {
      color: #fff;
      background-color: #FA6304;
      box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
      transform: translateY(-2px);
    }
    
    .button-26:active {
      box-shadow: none;
      transform: translateY(0);
    }


.signout {
    padding-top: 75px;
    padding-left: 30px;
}

.signin{
  padding-top: 100px;
}