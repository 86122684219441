.nopredict {
    background-color: #FA6304;
}

.chooseaud {

    padding-left: 100px;
}




.header2 {
    display: flex;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: center;
}


.chooseproducts {

    padding-left: 80px;
}



.products {
    padding-top: 50px;
}

.buttondiv {
    padding-left: 90px;
    padding-top: 25px;

} 


.addprodbutton {
    padding-top: 25px;
    margin-left: 75px;

}

.middle {
    margin-left: 10px;
}


.continue {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-left: 85px;
}


.done {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-left: 15px;
}

.reset {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-left: 90px;
}

.feedback {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-left: 30px;
}

.existtitle {
    padding-bottom: 12px;
}

.class {
    padding-top: 25px;
}


.offersubtitle {
    padding-bottom: 9px;
}

/* .header2{
    padding-bottom: 20px;
} */

.chooseprod {
    display: flex;
    justify-content: center;
}

.empty {
    padding-top: 27px;
}



.resultcard {
    padding-top: 25px;

}


.thirteen {
    background-color: lightgrey;
    color: black;
    padding-top: 4px;
    text-align: center;
}

.takeconfheader {
    background-color: white;
    color: white;
    
    
  }

  .take {
      background-color: black;
      /* padding-left: 2px; */
      text-align: center;
  }

  .conf {
      background-color: black;
      /* padding-left: 2px; */
      text-align: center;
  }

  
  .low {
    background-color: red;
    color: black;
    padding-top: 4px;
    padding-left: 2px;
    text-align: center;
  }
  
  .medium {
    background-color: yellow;
    color: black;
    padding-top: 4px;
    padding-left: 2px;
    text-align: center;
  }
  
  .high {
    background-color: green;
    color: black;
    padding-top: 4px;
    padding-left: 2px;
    text-align: center;
  }

.taketext {
    color:black;
}

.taketext2 {
    color:black;
    padding-bottom: 25px;
}

.carddiv {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-left: 40px;
}

.resultheader {
    padding-right: 10px;
}


.comp1 {
    display: flex;
    justify-content: center;
    
  }
  
  .comp2 {
    padding-left: 37px;
    
  }
  
  
  .comp3 {
    padding-left: 45px;
  }
  
  
  .addaud {
      display: flex;
      justify-content: center;
      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 30px;
      
  
  }
  
  .compbut {
      display: flex;
      justify-content: center;
      padding-top: 25px;
      padding-left: 25px;
  }
  
  .compare {
      display: flex;
      justify-content: center;
  }
  
  
  .change {
      display: flex;
      justify-content: center;
      padding-top: 25px;
      padding-left: 25px;
  }
  
  .compare2 {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 25px;
  }

  .compresults {
    display: flex;
    justify-content: center;    
    padding-top: 50px;
    padding-left: 40px;
}



.compaud {
    color:black;
}

.tfoot {
    color:black;
    
}

.tfoot-subtext {
    padding-top: 32px;
}



.compresults2 {
    display: flex;
    justify-content: center;    
    padding-top: 50px;
    padding-left: 40px;
}

.skucard {
    padding-top: 26px;
    padding-left: 85px;
}

.createnewcard {
    padding-top: 25px;
    padding-left: 80px;
}

/* CSS */
.button-24 {
    background: white;
    border: 1px solid white;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FA6304;
    border: 2px solid #FA6304;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    width: 60%;
    min-height: 40px;
    outline: 0;
    
    padding: 12px 14px;
    
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }
  
  .button-24:disabled {
      pointer-events: none;
    }
    
    .button-24:hover {
      color: #fff;
      background-color: #FA6304;
      box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
      transform: translateY(-2px);
    }
    
    .button-24:active {
      box-shadow: none;
      transform: translateY(0);
    }




    .button-23 {
        background: white;
        border: 1px solid white;
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
        box-sizing: border-box;
        color: #FA6304;
        border: 2px solid #FA6304;
        cursor: pointer;
        display: inline-block;
        font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
        font-size: 16px;
        font-weight: 800;
        line-height: 16px;
        width: 40%;
        min-height: 40px;
        outline: 0;
        
        padding: 12px 14px;
        
        text-align: center;
        text-rendering: geometricprecision;
        text-transform: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: middle;
      }
      
      .button-23:disabled {
          pointer-events: none;
        }
        
        .button-23:hover {
          color: #fff;
          background-color: #FA6304;
          box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
          transform: translateY(-2px);
        }
        
        .button-23:active {
          box-shadow: none;
          transform: translateY(0);
        }

.buttons {
   display: flex;
   justify-content: space-between;
    
}