.cmccard {
    display:flex;
    justify-content:flex-start;
    padding-top: 50px;
    margin-left: 10px;
    
}



.h1 {
    display: flex;
    justify-content: center;
    color:  #FA6304;
    padding-top: 15px;
    
}

.instructions {
    display: flex;
    justify-content: center;
    text-indent: 50px;
    
}


.App {
    padding-bottom: 300px;


}


.logo {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

